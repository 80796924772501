import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { useState } from "react";
import {
  AppendixComponent,
  BibliographyComponent,
  TableOfContentsComponent,
  TextEditorComponent,
} from "@/types/apiTypes";

export const TextEditor = ({
  component,
  handleComponentUpdate,
}: {
  component:
    | TextEditorComponent
    | BibliographyComponent
    | AppendixComponent
    | TableOfContentsComponent;
  handleComponentUpdate: (
    component:
      | TextEditorComponent
      | BibliographyComponent
      | AppendixComponent
      | TableOfContentsComponent,
  ) => void;
}) => {
  const [value, setValue] = useState(component.content);

  function handleChange(content) {
    setValue(content);
    handleComponentUpdate({
      type: component.type,
      content,
    });
  }
  return (
    <SunEditor
      autoFocus={true}
      lang="en"
      width="730px"
      setOptions={{
        showPathLabel: false,
        minHeight: "65vh",
        maxHeight: "65vh",
        placeholder: "Enter your text here!!!",
        buttonList: [
          ["formatBlock"],
          ["bold", "underline", "italic", "strike", "subscript", "superscript"],
          ["list", "align", "outdent", "indent"],
        ],
        formats: ["h1", "h2", "h3", "h4", "h5", "h6", "p"],
      }}
      defaultValue={value}
      onChange={handleChange}
    />
  );
};
