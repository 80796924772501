import { HtmlStyles } from "react-pdf-html";
import {
  H1FontSize,
  H2FontSize,
  H3FontSize,
  H4FontSize,
  H5FontSize,
  H6FontSize,
  PFontSize,
} from "./pdfReportStyles";

export const pdfReportHtmlDefaultStyles: HtmlStyles = {
  body: {
    fontFamily: "Roboto",
    margin: 0,
    fontSize: PFontSize,
    color: "#333333",
  },
  h1: {
    fontSize: H1FontSize,
    fontWeight: 600,
    marginVertical: 16,
  },
  h2: {
    fontSize: H2FontSize,
    fontWeight: 600,
    marginVertical: 16,
  },
  h3: {
    fontSize: H3FontSize,
    fontWeight: 600,
    marginVertical: 16,
  },
  h4: {
    fontSize: H4FontSize,
    fontWeight: 600,
    marginVertical: 16,
  },
  h5: {
    fontSize: H5FontSize,
    fontWeight: 600,
    marginVertical: 16,
  },
  h6: {
    fontSize: H6FontSize,
    fontWeight: 600,
    marginVertical: 16,
  },
  p: {
    fontSize: PFontSize,
    lineHeight: 1.5,
    margin: 0,
  },
  ul: {
    marginTop: 12,
    marginBottom: 0,
  },
  li: {
    lineHeight: 1,
    margin: 0,
    marginVertical: 3,
  },
};

export const pdfReportTableOfContentsStyles: HtmlStyles = {
  ...pdfReportHtmlDefaultStyles,
  body: {
    fontFamily: "Roboto",
    margin: 0,
    fontSize: PFontSize,
    color: "#333333",
  },
  h1: {
    fontSize: H1FontSize,
    fontWeight: 600,
    marginVertical: 16,
  },
  h2: {
    fontSize: H4FontSize,
    fontWeight: 500,
    marginVertical: 8,
  },
  h3: {
    fontSize: H5FontSize,
    fontWeight: 500,
    marginVertical: 8,
  },
};

export const pdfReportBibliographyStyles: HtmlStyles = {
  ...pdfReportHtmlDefaultStyles,
  p: {
    fontSize: PFontSize,
    lineHeight: 1.5,
    margin: 4,
  },
};