import { BibliographyComponent, TextEditorComponent } from "@/types/apiTypes";
import { replaceUnderScores } from "../utility";
import { HTMLComponent } from "./HTMLComponent";

export const PdfPreviewTextComponent = ({
  component,
  reportTemplateValues,
}: {
  component: TextEditorComponent | BibliographyComponent;
  reportTemplateValues: { [key: string]: string };
}) => {
  let content = component.content || "";
  if (component.content) {
    content = transformText(component.content, reportTemplateValues);
  }
  return <HTMLComponent content={content} />;
};

function transformText(
  content: string,
  reportTemplateValues: { [key: string]: string },
) {
  let updatedContent = replaceUnderScores(content);

  updatedContent = updateContentWithTemplateValues(
    updatedContent,
    reportTemplateValues,
  );

  updatedContent = updateContentWithReferences(updatedContent);

  return updatedContent;
}

function updateContentWithTemplateValues(
  content: string,
  reportTemplateValues: { [key: string]: string },
) {
  const regexp = /(?<={).+?(?=})/g;
  const matches = content.match(regexp);

  if (matches) {
    matches.forEach((e) => {
      if (reportTemplateValues[e]) {
        content = content.replaceAll(`{${e}}`, reportTemplateValues[e]);
      }
    });
  }

  return content;
}

function updateContentWithReferences(content: string) {
  let updatedContent = content;
  const regexp = /(?<=&lt;reference&gt;).+?(?=&lt;\/reference&gt;)/g;
  const matches = content.match(regexp);

  matches?.forEach((e) => {
    const [reference] = e.split("|");
    updatedContent = updatedContent.replace(
      `&lt;reference&gt;${e}&lt;/reference&gt;`,
      reference,
    );
  });

  return updatedContent;
}
