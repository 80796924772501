import {
  AddReportPosition,
  Report,
  ReportComponent,
  ReportComponentIds,
  ReportData,
} from "@/types/apiTypes";
import { ReportModuleComponent } from "./ReportModuleComponent";

export function ReportModuleComponents({
  projectData,
  report,
  components,
  saveLoaderVisibility,
  handleComponentChanges,
}: {
  projectData: ReportData;
  report: Report;
  components: ReportComponent[];
  saveLoaderVisibility: boolean;
  handleComponentChanges: (
    components: ReportComponent[],
    updatedReportComponentId?: ReportComponentIds,
  ) => Promise<void>;
}) {
  async function handleComponentUpdate(
    component: ReportComponent,
    index: number,
  ) {
    const componentsAfterUpdate = [...components];
    componentsAfterUpdate[index] = component;
    await handleComponentChanges(componentsAfterUpdate);
  }

  async function handleComponentDelete(index: number) {
    const componentsAfterUpdate = [...components];
    componentsAfterUpdate.splice(index, 1);
    await handleComponentChanges(componentsAfterUpdate);
  }

  async function handleAddModule(
    index: number,
    position: AddReportPosition,
    addedComponents: ReportComponent[],
  ) {
    const insertionIndex = position === "after" ? index + 1 : index;
    const componentsAfterUpdate = [...components];

    componentsAfterUpdate.splice(insertionIndex, 0, ...addedComponents);
    await handleComponentChanges(
      componentsAfterUpdate,
      // Change this if we can add multiple components in future
      addedComponents[0].type,
    );
  }
  return (
    <div className="shrink-0 grow-0 basis-3/6 overflow-x-scroll pr-4">
      {components.map((component, index) => (
        <ReportModuleComponent
          report={report}
          project={projectData}
          key={index}
          component={component}
          index={index}
          isAddEnabled={component.type !== "introduction"}
          isDeleteEnabled={
            component.type !== "introduction" &&
            component.type !== "bibliography" &&
            components.length > 2
          }
          isEditEnabled={
            !(component.type === "pageBreak" || component.type === "area")
          }
          saveLoaderVisibility={saveLoaderVisibility}
          handleComponentUpdate={handleComponentUpdate}
          handleComponentDelete={handleComponentDelete}
          handleAddModule={handleAddModule}
        />
      ))}
    </div>
  );
}
