import { Font, StyleSheet } from "@react-pdf/renderer";

export const H1FontSize = 28;
export const H2FontSize = 24;
export const H3FontSize = 20;
export const H4FontSize = 18;
export const H5FontSize = 16;
export const H6FontSize = 14;
export const LabelFontSize = 12;
export const PFontSize = 13;

Font.register({
  family: "Roboto",
  src: "/fonts/Roboto-Regular.ttf",
  fontStyle: "normal",
  fontWeight: "normal",
});

Font.register({
  family: "Roboto",
  src: "/fonts/Roboto-Bold.ttf",
  fontStyle: "normal",
  fontWeight: "bold",
});

Font.register({
  family: "Roboto",
  src: "/fonts/Roboto-Medium.ttf",
  fontStyle: "normal",
  fontWeight: "medium",
});

export const pdfGeneralStyles = StyleSheet.create({
  generalPage: {
    paddingTop: 96,
    paddingBottom: 60,
    fontFamily: "Roboto",
  },
  content: {
    paddingHorizontal: 64,
  },
  mapImage: {
    top: 10,
    bottom: 10,
    left: 0,
    height: "auto",
    container: {
      marginBottom: 20,
    },
    description: {
      display: "inline-block",
      fontSize: PFontSize,
      color: "#333333",
      top: 5,
      bottom: 5,
      textAlign: "left",
    },
    noAvailable: {
      backgroundColor: "#b8b8b8",
      height: 150,
      width: 300,
      text: {
        display: "inline-block",
        fontSize: H6FontSize,
        color: "#333333",
        margin: "auto",
      },
    },
  },
});

export const pdfHeaderStyles = StyleSheet.create({
  header: {
    position: "absolute",
    top: 0,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: 64,
    paddingRight: 20,
    paddingTop: 10,
  },
  logo: {
    width: 150,
  },
  reportTitle: {
    fontSize: H4FontSize,
  },
});

export const pdfFooterStyles = StyleSheet.create({
  footer: {
    position: "absolute",
    height: 20,
    width: "100%",
    backgroundColor: "#77cb00",
    bottom: 0,
  },
});

export const pdfPageNumberStyles = StyleSheet.create({
  pageNumber: {
    position: "absolute",
    fontSize: LabelFontSize,
    bottom: 40,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

export const pdfIntroductionStyles = StyleSheet.create({
  introductionPage: {
    paddingBottom: 80,
    height: "80%",
  },
  introductionPageLogo: {
    marginLeft: "11%",
    width: 600,
  },
  coverPhoto: {
    marginTop: 50,
    width: "100%",
    height: 450,
  },
  introductionPageReportDetailsContainer: {
    position: "absolute",
    display: "flex",
    flexDirection: "row",
    height: 200,
    width: "100%",
    bottom: 0,
    paddingHorizontal: 78,
    fontSize: H4FontSize,
  },
  reportTitleContainer: {
    flexGrow: 10,
    rowGap: 20,
  },
  reportDateContainer: {
    flexGrow: 2,
  },
});

export const pdfTableStyles = StyleSheet.create({
  tableContainer: {
    marginVertical: 15,
  },

  tableHeaderContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  tableHeader: {
    fontSize: LabelFontSize,
    fontWeight: "bold",
    padding: 8,
    color: "#474747",
  },
  tableData: {
    padding: 8,
    display: "flex",
    flexDirection: "column",
    rowGap: 5,
    color: "#333333",
  },

  tableRowContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: PFontSize,
    backgroundColor: "#f5f5f5",
  },
  tableEvenRow: {
    backgroundColor: "#ededed",
  },
});

export const pdfAppendixStyles = StyleSheet.create({
  container: {
    fontSize: PFontSize,
    color: "#333333",
  },
  title: {
    fontSize: H1FontSize,
    fontWeight: "bold",
    marginBottom: 8,
  },
  observationContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "0 0 16px 0",
  },
  observationName: {
    fontSize: H2FontSize,
    fontWeight: "bold",
    marginBottom: 16,
  },

  propertyContainer: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#f5f5f5",
    padding: "10px 8px",
    width: "100%",
  },
  oddRow: {
    backgroundColor: "#ededed",
  },
  propertyKey: {
    flexBasis: "25%",
  },
  propertyValue: {
    flexBasis: "75%",
  },

  observationPhotoContainer: {
    display: "flex",
    flexDirection: "row",
    rowGap: 10,
    columnGap: 10,
    flexWrap: "wrap",
    marginVertical: 8,
  },
  observationMapContainer: {
    marginTop: 8,
  },
  observationMap: {
    width: 300,
    height: 300,
  },
  observationMapPlaceholder: {
    width: 300,
    height: 300,
    backgroundColor: "#b8b8b8",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },

  observationPhoto: {
    width: 300,
    height: 300,
  },

  allObservationsTitle: {
    fontSize: H4FontSize,
  },
  otherMediaContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    columnGap: 5,
  },
  audio: {
    width: 25,
  },
});

export const pdfLegendStyle = StyleSheet.create({
  legendContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    margin: "10px 0",
  },
  legendItem: {
    display: "flex",
    alignItems: "center",
    marginRight: "15px",
    flexDirection: "row",
  },
  colorCircle: {
    width: "15px",
    height: "15px",
    borderRadius: "50%",
    marginRight: "8px",
  },
  speciesName: {
    fontSize: "14px",
    color: "#333",
  },
});
