import {
  AddReportPosition,
  AreaGroup,
  Report,
  ReportComponent,
  ReportComponentIds,
  ReportData,
} from "@/types/apiTypes";
import { Dialog, RadioGroup, toaster } from "evergreen-ui";
import { useState } from "react";
import { DialogFooterComponent } from "./DialogFooterComponent";
import {
  generateAppendixComponent,
  generateAreaComponent,
  generateImageComponent,
  generatePageBreakComponent,
  generateTableOfContentsComponent,
  generateTextEditorComponent,
} from "../utility";
import { moduleTypeOptions, positionOptions } from "../constants";
import { AreasListComponent } from "./AreasListComponent";
import { useApi } from "@/context/AxiosContext";
import { ImageUploadComponent } from "./ImageUploadComponent";

export function AddReportModuleDialogComponent({
  projectData,
  report,
  addReportModuleDialogVisibility,
  saveLoaderVisibility,
  handleOnClose,
  handleOnSave,
}: {
  projectData: ReportData;
  report: Report;
  addReportModuleDialogVisibility: boolean;
  saveLoaderVisibility: boolean;
  handleOnClose: () => void;
  handleOnSave: (
    position: AddReportPosition,
    components: ReportComponent[],
  ) => void;
}) {
  const {
    areas,
    project: { id },
  } = projectData;

  const [positionValue, setPositionValue] =
    useState<AddReportPosition>("before");
  const [moduleType, setModuleType] =
    useState<ReportComponentIds>("textEditor");
  const [image, setImage] = useState<{ file?: File; description: string }>({
    description: "",
  });

  const [isConfirmEnabled, setIsConfirmEnabled] = useState(true);
  const { apiInstance } = useApi();

  const [areaGroups, setAreaGroups] = useState<AreaGroup[]>([]);

  async function handleConfirmDialog() {
    let components: ReportComponent[] = [];
    switch (moduleType) {
      case "textEditor":
        components.push(generateTextEditorComponent());
        break;

      case "pageBreak":
        components.push(generatePageBreakComponent());
        break;

      case "area":
        areaGroups.forEach((e, index) => {
          components.push(
            generateAreaComponent(
              e,
              index,
              report.additionalData.observationTypes,
              areaGroups.length === 1,
            ),
          );
        });
        break;

      case "image":
        const imageResponse = await handleFileUpload(image.file!);
        components.push(
          generateImageComponent(imageResponse!, image.description),
        );
        break;

      case "appendix":
        components.push(generateAppendixComponent());
        break;

      case "tableOfContents":
        try {
          components.push(
            generateTableOfContentsComponent(report.components),
            generatePageBreakComponent(),
          );
          break;
        } catch (err) {
          toaster.danger(`${err}`);
        }
    }

    if (components.length) {
      handleOnSave(positionValue, components);
    }
  }

  function handleModuleOnChange(event) {
    const option = event.target.value as ReportComponentIds;
    setModuleType(option);
    if (option === "image" || (option === "area" && areas.length > 1)) {
      setIsConfirmEnabled(false);
    } else {
      setIsConfirmEnabled(true);
    }
  }

  function handleFileUpload(file: File) {
    try {
      return apiInstance!.adminReports.uploadMedia(id, file);
    } catch (e) {
      console.error("Filed to upload media", e);
    }
  }

  function handleImageSelection(uploadedImage: {
    file?: File;
    description: string;
  }) {
    setImage(uploadedImage);
    setIsConfirmEnabled(!!(uploadedImage.file && uploadedImage.description));
  }

  function handleAreaGroupsChange(areaGroups: AreaGroup[]) {
    setAreaGroups(areaGroups);
    setIsConfirmEnabled(!!areaGroups.length);
  }

  return (
    <Dialog
      title="Add Module"
      width="90%"
      shouldCloseOnOverlayClick={false}
      topOffset="2%"
      shouldCloseOnEscapePress={false}
      isShown={addReportModuleDialogVisibility}
      onCloseComplete={handleOnClose}
      footer={
        <DialogFooterComponent
          saveLoaderVisibility={saveLoaderVisibility}
          handleCloseDialog={handleOnClose}
          handleConfirmDialog={handleConfirmDialog}
          isConfirmEnabled={isConfirmEnabled}
        />
      }
    >
      <div className="flex">
        <RadioGroup
          label="Position"
          size={16}
          value={positionValue}
          options={positionOptions}
          onChange={(event) =>
            setPositionValue(event.target.value as AddReportPosition)
          }
        />
        <RadioGroup
          marginLeft={72}
          label="Module Type"
          size={16}
          value={moduleType}
          options={moduleTypeOptions}
          onChange={handleModuleOnChange}
        />
      </div>
      <div className="mt-4">
        {moduleType === "image" && (
          <ImageUploadComponent
            image={image!}
            handleImageSelection={handleImageSelection}
          />
        )}
        {moduleType === "area" && (
          <div className="rounded border border-zinc-200 p-4 drop-shadow-sm">
            <AreasListComponent
              areas={areas}
              handleAreaGroupsChange={handleAreaGroupsChange}
            />
          </div>
        )}
      </div>
    </Dialog>
  );
}
