import { TableComponent, TableComponentData } from "@/types/apiTypes";
import { TablePreviewComponent } from "../preview-components/TablePreviewComponent";
import { Button, TextInput } from "evergreen-ui";
import { useState } from "react";

export const TableEditor = ({
  component,
  handleAddedRows,
}: {
  component: TableComponent;
  handleAddedRows: (addedRows: TableComponentData[]) => void;
}) => {
  const [componentForAddedRows, setComponentForAddedRows] =
    useState<TableComponent>({
      ...component,
      data: [],
    });
  const [newRow, setNewRow] = useState<TableComponentData>(getNewRow());

  function getNewRow() {
    const columnKeys = {};
    component.columns
      .map((e) => e.key)
      .forEach((key) => {
        columnKeys[key] = "";
      });

    return columnKeys;
  }

  function handleNewRowChange(e) {
    setNewRow({ ...newRow, [e.target.name]: e.target.value });
  }

  function handleAddNewRow() {
    const rows = [
      ...componentForAddedRows.data,
      {
        ...newRow,
        activityNo: `${component.data.length + componentForAddedRows.data.length + 1}`,
      },
    ];
    setComponentForAddedRows({
      ...componentForAddedRows,
      data: rows,
    });
    setNewRow(getNewRow());
    handleAddedRows(rows);
  }
  return (
    <TablePreviewComponent component={component}>
      {component.tableIdentifier.tableType === "visit" && (
        <div className="mt-8">
          <span className="text-lg text-zinc-600">
            Optionally add rows to the above table
          </span>
          <div className="mt-4 flex flex-col gap-y-4 text-sm text-zinc-600">
            {component.columns.map((e, index) => {
              if (e.label !== "#") {
                return (
                  <div key={index} className="flex-column flex">
                    <span className="basis-32">{e.label}</span>
                    <TextInput
                      className="basis-4"
                      marginLeft={16}
                      name={e.key}
                      onChange={handleNewRowChange}
                      value={newRow[e.key]}
                    />
                  </div>
                );
              }
              return null;
            })}
          </div>
          <div>
            <Button
              appearance="primary"
              marginTop={16}
              onClick={handleAddNewRow}
            >
              Add Row
            </Button>
          </div>
          <div className="mt-4">
            <TablePreviewComponent component={componentForAddedRows} />
          </div>
        </div>
      )}
    </TablePreviewComponent>
  );
};
