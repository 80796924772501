import {
  AddReportPosition,
  AreaComponent,
  Report,
  ReportComponent,
  ReportData,
} from "@/types/apiTypes";
import { ReportModuleComponent } from "../modules/ReportModuleComponent";

export const AreaPreviewComponent = ({
  project,
  report,
  component,
  saveLoaderVisibility,
  handleComponentChanges,
}: {
  project: ReportData;
  report: Report;
  component: AreaComponent;
  saveLoaderVisibility: boolean;
  handleComponentChanges: (components: ReportComponent[]) => void;
}) => {
  const { childComponents } = component;

  async function handleComponentUpdate(
    component: ReportComponent,
    index: number,
  ) {
    const childComponentsAfterUpdate = [...childComponents];
    childComponentsAfterUpdate[index] = component;
    await handleComponentChanges(childComponentsAfterUpdate);
  }

  async function handleAddModule(
    index: number,
    position: AddReportPosition,
    addedComponents: ReportComponent[],
  ) {
    const insertionIndex = position === "after" ? index + 1 : index;
    const childComponentsAfterUpdate = [...component.childComponents];

    childComponentsAfterUpdate.splice(insertionIndex, 0, ...addedComponents);
    await handleComponentChanges(childComponentsAfterUpdate);
  }

  async function handleComponentDelete(index: number) {
    const childComponentsAfterUpdate = [...component.childComponents];
    childComponentsAfterUpdate.splice(index, 1);
    await handleComponentChanges(childComponentsAfterUpdate);
  }

  return (
    <>
      {childComponents.map((childComponent, index) => (
        <ReportModuleComponent
          report={report}
          project={project}
          key={index}
          component={childComponent}
          handleComponentUpdate={handleComponentUpdate}
          index={index}
          saveLoaderVisibility={saveLoaderVisibility}
          isAddEnabled
          isDeleteEnabled
          isEditEnabled={
            !(
              childComponent.type === "pageBreak" ||
              childComponent.type === "area"
            )
          }
          handleAddModule={handleAddModule}
          handleComponentDelete={handleComponentDelete}
        />
      ))}
    </>
  );
};
