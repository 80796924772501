import {
  BibliographyComponent,
  ReportComponent,
  TextEditorComponent,
} from "@/types/apiTypes";
import { HTMLComponent } from "./HTMLComponent";
import { generateH2Tag, generatePTag } from "../utility";
import { pdfReportBibliographyStyles } from "./styles/pdfReportContentStyles";

export const PdfPreviewBibliographyComponent = ({
  bibliographyComponent,
  flattenedComponents,
}: {
  bibliographyComponent: BibliographyComponent;
  flattenedComponents: ReportComponent[];
}) => {
  let generatedReferences = generateReferences(flattenedComponents);
  if (generatedReferences) {
    generatedReferences = `${generateH2Tag("Verwijzingen")}${generatedReferences}`;
  }
  let manualReferences = getReferencesManuallyTyped(bibliographyComponent);

  const manualReferencesStripped = bibliographyComponent.content.replace(
    manualReferences,
    "",
  );

  if (manualReferences) {
    manualReferences = `${generateH2Tag("Geraadpleegde literatuur")}${manualReferences}`;
  }
  const computedReferences = `${manualReferencesStripped}${generatedReferences}${manualReferences}`;
  return (
    <HTMLComponent
      content={computedReferences}
      styles={pdfReportBibliographyStyles}
    />
  );
};

function getReferencesManuallyTyped(
  bibliographyComponent: BibliographyComponent,
) {
  const regexp = /(<p>).+?(<\/p>)/g;
  const matches = bibliographyComponent.content.match(regexp);

  return matches?.join("") || "";
}

function generateReferences(flattenedComponents: ReportComponent[]) {
  const textComponents = flattenedComponents.filter(
    (e) => e.type === "textEditor",
  );

  return `${getReferences(textComponents).join("")}`;
}

function getReferences(textComponents: TextEditorComponent[]) {
  const references: string[] = [];
  textComponents.forEach((e) => {
    const regexp = /(?<=&lt;reference&gt;).+?(?=&lt;\/reference&gt;)/g;
    const matches = e.content.match(regexp);

    matches?.forEach((e) => {
      const referenceDisplayedInAppendix = e.split("|")[1];
      references.push(generatePTag(referenceDisplayedInAppendix));
    });
  });

  return references;
}
