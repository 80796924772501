import {
  AppendixComponent,
  MediaItems,
  ReportComponent,
  TableComponent,
  TableComponentData,
} from "@/types/apiTypes";
import { Image, Text, View } from "@react-pdf/renderer";
import { pdfAppendixStyles } from "./styles/pdfReportStyles";
import { appendixObservationTableColumns } from "../constants";
import { PdfPreviewTableComponent } from "./PdfPreviewTableComponent";
import { memo, useEffect, useState } from "react";
import { fetchImagesUrls, fetchUrl } from "../utility";
import { getFormattedDate } from "../utility";
import { HTMLComponent } from "./HTMLComponent";

export const PdfPreviewAppendixComponent = ({
  appendixComponent,
  components,
  speciesMap,
  mediaItems,
}: {
  appendixComponent: AppendixComponent;
  components: ReportComponent[];
  speciesMap: { [specie: string]: TableComponentData[] };
  mediaItems: MediaItems;
}) => {
  const areaComponents = components.filter((e) => e.type === "area");
  const tableComponents: TableComponent[] = Object.keys(speciesMap).map(
    (specie) => ({
      type: "table",
      tableIdentifier: {
        tableType: "observation",
        origianalIndexReference: 0,
        specie,
      },
      columns: appendixObservationTableColumns,
      data: speciesMap[specie],
    }),
  );

  return (
    <View style={pdfAppendixStyles.container}>
      <HTMLComponent content={appendixComponent.content} />
      {areaComponents.map((area, index) => {
        return (
          <View key={index}>
            {area.childComponents.map((e) => {
              if (
                e.type === "table" &&
                e.tableIdentifier.tableType === "observation"
              ) {
                return e.data.map((observation, index) => {
                  return (
                    <ObservationProperties
                      key={index}
                      observation={observation}
                      mediaItems={mediaItems}
                    />
                  );
                });
              }
              return null;
            })}
          </View>
        );
      })}
      <View break>
        <Text style={pdfAppendixStyles.allObservationsTitle}>
          Overzicht van alle gemaakte observaties tijdens de veldbezoeken:
        </Text>
        {tableComponents.map((tableComponent, index) =>
          tableComponent.data.length ? (
            <PdfPreviewTableComponent key={index} component={tableComponent} />
          ) : null,
        )}
      </View>
    </View>
  );
};

const ObservationProperties = ({
  observation,
  mediaItems,
}: {
  observation: TableComponentData;
  mediaItems: MediaItems;
}) => {
  const observationProperties: Array<{ label: string; value: string } | null> =
    [
      {
        label: "Date",
        value: observation.createdAt
          ? getFormattedDate(observation.createdAt)
          : "",
      },
      {
        label: "Location",
        value: `${observation.address} ${observation.locationInfo}`,
      },
      {
        label: "Count",
        value: observation.count,
      },
      observation.activityType
        ? {
            label: "Activity type",
            value: observation.activityType.name,
          }
        : null,
      {
        label: "Findings",
        value: `${
          observation.type === "bat" && observation.finding === "verblijfplaats"
            ? observation.bat_typeOfResidence
            : ""
        } ${observation.finding}`,
      },
      observation.type === "bat" && observation.finding === "vliegroute"
        ? {
            label: "Flying Route",
            value: observation.bat_flyRouteDirection,
          }
        : null,
      observation.finding === "overig" && observation.findingNotes
        ? {
            label: "Findings notes",
            value: observation.findingNotes,
          }
        : null,
      {
        label: "Notes",
        value: observation.notes,
      },
      {
        label: "Observed Behaviours",
        value:
          observation.observedBehaviours &&
          observation.observedBehaviours.join(", "),
      },
      {
        label: "Reference Id",
        value: `${observation.workerId} - ${observation.originalShiftId}`,
      },
    ].filter((e) => e && e.value);

  return (
    <View style={pdfAppendixStyles.observationContainer} wrap={false}>
      <Text style={pdfAppendixStyles.observationName}>
        {observation.appendixReferenceDisplayedInAppendixSection}
      </Text>
      {observationProperties.map(
        (e, index) =>
          e && (
            <View
              key={index}
              style={[
                pdfAppendixStyles.propertyContainer,
                index % 2 === 1 ? pdfAppendixStyles.oddRow : {},
              ]}
            >
              <Text style={pdfAppendixStyles.propertyKey}>{e.label}</Text>
              <Text style={pdfAppendixStyles.propertyValue}>{e.value}</Text>
            </View>
          ),
      )}
      {observation.media ? <MediaFiles medias={observation.media} /> : null}
      {observation.mapImage ? (
        <MapImage
          mapImage={observation.mapImage}
          mediaItems={mediaItems}
          observationProperties={observation}
        />
      ) : null}
    </View>
  );
};

const MapImage = memo(
  ({
    observationProperties,
    mediaItems,
    mapImage,
  }: {
    mediaItems: MediaItems;
    mapImage: { mediaItemId: number };
    observationProperties: TableComponentData;
  }) => {
    const mediaItemId = mapImage.mediaItemId;
    const mediaItem = mediaItemId ? mediaItems?.[mediaItemId] : undefined;
    const uploaded = mediaItem ? mediaItem.uploaded : false;

    const [mapUrl, setMapUrl] = useState<string>();
    useEffect(() => {
      async function fetchMapImage() {
        if (!mediaItem || !uploaded) {
          return;
        }
        const url = await fetchUrl(mediaItem?.fullPath);
        setMapUrl(url);
      }
      fetchMapImage();
    }, [mediaItem, uploaded]);

    return (
      <View style={pdfAppendixStyles.observationMapContainer}>
        {mapUrl ? (
          <Image style={pdfAppendixStyles.observationMap} src={mapUrl} />
        ) : (
          <View style={pdfAppendixStyles.observationMapPlaceholder}>
            <Text>Map not available</Text>
          </View>
        )}
      </View>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.observationProperties.id === nextProps.observationProperties.id
    );
  },
);

const MediaFiles = memo(
  ({
    medias,
  }: {
    medias: { fullPath: string; mimeType: string; originalFileName: string }[];
  }) => {
    const [imageUrls, setImageUrls] = useState<Array<string>>([]);
    const otherMedias = getOtherMediaFiles(medias);
    useEffect(() => {
      async function fetchImagesFromMedia() {
        const urls = await fetchImagesUrls(medias);
        setImageUrls(urls);
      }
      fetchImagesFromMedia();
    }, [medias]);

    return (
      <View>
        <View style={pdfAppendixStyles.observationPhotoContainer}>
          {imageUrls.map((imageUrl, index) => {
            return (
              <Image
                key={index}
                style={pdfAppendixStyles.observationPhoto}
                src={imageUrl}
              />
            );
          })}
        </View>
        {otherMedias.map((e, index) => (
          <View key={index} style={pdfAppendixStyles.otherMediaContainer}>
            <Image style={pdfAppendixStyles.audio} src={`/${e.fileType}.png`} />
            <Text>{e.fileName}</Text>
          </View>
        ))}
      </View>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.medias === nextProps.medias;
  },
);

function getOtherMediaFiles(
  medias: { fullPath: string; mimeType: string; originalFileName: string }[],
) {
  const fileNames: Array<{ fileName: string; fileType: string }> = [];
  medias.forEach((media) => {
    const { mimeType, originalFileName } = media;

    if (!mimeType) {
      console.error("mimeType not found", media);
      return;
    }
    const [fileType] = mimeType.split("/");
    if (fileType !== "image") {
      fileNames.push({ fileName: originalFileName, fileType });
    }
  });

  return fileNames;
}
