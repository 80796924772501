import { Text, Styles, View } from "@react-pdf/renderer";
import { MapImageComponent, MediaItems } from "@/types/apiTypes";
import { PdfPreviewImage } from "./PdfPreviewImage";
import { pdfGeneralStyles } from "./styles/pdfReportStyles";

type PdfPreviewMapImageProps = {
  mapImageComponent: MapImageComponent;
  mediaItems: MediaItems;
};

export const PdfPreviewMapImage = ({
  mapImageComponent,
  mediaItems,
}: PdfPreviewMapImageProps) => {
  const mediaItemId = mapImageComponent?.mediaItemId;
  const mediaItem = mediaItemId ? mediaItems?.[mediaItemId] : undefined;
  const uploaded = mediaItem ? mediaItem.uploaded : false;
  return (
    <View style={pdfGeneralStyles.mapImage.container}>
      {!uploaded ? (
        <View style={pdfGeneralStyles.mapImage.noAvailable as Styles}>
          <Text style={pdfGeneralStyles.mapImage.noAvailable.text as Styles}>
            Map not available
          </Text>
        </View>
      ) : (
        <PdfPreviewImage
          mediaItems={mediaItems}
          imageComponent={mapImageComponent}
          styles={pdfGeneralStyles.mapImage as Styles}
          showDescription={true}
        />
      )}
    </View>
  );
};
